<template>
  <div class="smb-client-wrapper">
    <SmbTitles is-show-title is-show-sub-title title="Settings & Profile" />
    <div class="form-container">
      <div class="form-container__title">
        Add up to 30 hashtags that will appear on all your posts. It’s
        recommended not to add more than 5 constant hashtags.
      </div>
      <div class="form-container__tags">
        <v-combobox
          :delimiters="[',', ' ', ';', '#']"
          @change="hashtagsChangedHandler"
          v-model="hashtagsList"
          chips
          hide-details
          class="tags_container"
          label="Hashtags Constant"
          multiple
          append-icon=""
          solo
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="deleteHashtagConstantHandler(item)"
            >
              <strong>{{ item }}</strong>
            </v-chip>
          </template>
        </v-combobox>
      </div>
    </div>
    <div class="footer-buttons">
      <SmbActionBtn
        class="arrow-button"
        isUseIcon
        iconName="mdi-arrow-left"
        width="20%"
        @click="$router.push({ name: 'profile-settings' })"
      />
      <SmbActionBtn
        width="80%"
        :isLoading="isSaveBtnLoading"
        :text="
          isHashtagsChanged && hashtagsList.length < 31 ? 'Save' : 'Cancel'
        "
        :emitName="
          isHashtagsChanged && hashtagsList.length < 31 ? 'save' : 'cancel'
        "
        @cancel="$router.push({ name: 'profile-settings' })"
        @save="saveHandler"
      />
    </div>
  </div>
</template>

<script>
import SmbTitles from "@/components/smb-client/SmbTitles";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn.vue";
import { mapActions, mapGetters } from "vuex";
import H from "@/utils/helper";
export default {
  name: "Index",
  data: () => ({
    isSaveBtnLoading: false,
    isHashtagsChanged: false,
    hashtagsList: [],
  }),
  components: { SmbTitles, SmbActionBtn },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
  },
  created() {
    this.getCurrentSmbCustomer.hashtags_constant.length
      ? (this.hashtagsList = this.getCurrentSmbCustomer.hashtags_constant)
      : (this.hashtagsList = []);
  },
  methods: {
    ...mapActions("clientSelection", ["updateCustomer"]),
    ...mapActions("smbClientSelection", ["fetchSmbClientInfo"]),
    async saveHandler() {
      try {
        this.isSaveBtnLoading = true;
        await this.updateCustomer({
          customerId: this.getCurrentSmbCustomer.id,
          params: {
            hashtags_constant: this.hashtagsList,
          },
        });
        await this.fetchSmbClientInfo(this.getCurrentSmbCustomer.id);
        this.isSaveBtnLoading = false;
        await this.$router.push({ name: "profile-settings" });
      } catch (e) {
        console.log(e);
      }
    },
    hashtagsChangedHandler(val) {
      if (!val && !val.length) return;
      this.hashtagsList = H.hashtagsCompose(val);
      if (this.hashtagsList.length > 30) {
        this.hashtagsList.splice(-1);
        return;
      }
      this.isHashtagsChanged = true;
    },
    deleteHashtagConstantHandler(tag) {
      this.isHashtagsChanged = true;
      this.hashtagsList = this.hashtagsList.filter((val) => val !== tag);
    },
  },
};
</script>

<style scoped lang="scss">
.smb-client-wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f3f2f3;
  height: 100%;
  .form-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 15px;
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #1f2325;
      margin-top: 20px;
      margin-bottom: 20px;
      max-width: 500px;
    }
    &__tags {
      max-width: 500px;
      ::v-deep {
        .v-text-field .v-input__slot {
          box-shadow: none !important;
          border: 1px solid #c7c9ca;
          min-height: 142px;
          align-items: flex-start;
        }
        .v-select.v-select--chips .v-select__selections {
          align-items: flex-start;
          padding: 10px 10px;
        }
      }
    }
  }
  .footer-buttons {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    letter-spacing: 0;
    position: relative;
    @media screen and (min-width: 760px) {
      margin-top: 50px;
      max-width: 500px;
      padding: 0 20px 10px 20px;
      justify-content: flex-end;
    }

    .button-item {
      width: 100%;
      @media screen and (min-width: 760px) {
        width: 313px;
      }
    }
    .arrow-button {
      width: 20%;
      position: absolute;
      left: 0;
      z-index: 10;
      @media screen and (min-width: 760px) {
        display: none;
      }
    }
  }
}
</style>
